<template>
    <div> 
        <b-col v-if="this.statements.length > 0">
            <b-table             
                ref="selectableTable"
                :fields="fieldsViewStatement" 
                :items="statements"                             
                responsive                
                selectable
                class="mb-0"
                :select-mode="selectMode"
                @row-selected="onRowSelected"                
                >
                <template #cell(createDate)="data">                                
                    {{((data.item.createDate.substring(0,10)))}}
                </template>  
                
                <template #cell(transactionDescription)="data">
                    <p style="margin:0px">{{data.item.transactionDescription}}</p>
                </template>

                <template #cell(transactionAmount)="data">
                    ${{parseFloat(data.item.transactionAmount).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}}
                </template>
            </b-table>
        </b-col>

        <b-col v-else>
            <p class="mt-2">Este Neoganadero aún no tiene transacciones...</p>
        </b-col>
    </div>
</template>

<script>

import axios from '@axios'
import {
    BTable, BCol 
} from 'bootstrap-vue'

export default {
  name:"ViewStatementComponent",
  props:{
    userID: null
  },
  components: { 
    BTable, 
    BCol
  },
  data(){
    return{
        selectMode: 'single',     
        fieldsViewStatement: [
                {key: 'createDate', label: 'Fecha', thStyle: { width: "30%" }},
                {key: 'transactionDescription', label: 'Descripción', thStyle: { width: "50%" }},
                {key: 'transactionAmount', label: 'Monto transacción', thStyle: { width: "20%" }}
            ],
        createDate: null,
        transactionDescription: null,
        transactionAmount: null,
        statements: [{}],
        }
    },
    created(){
        this.GetViewStatementByInvestor();
    },

    methods:{
        GetViewStatementByInvestor(){
            axios.get('/MoneyTransaction/GetViewStatementByInvestor',  { params: { userID:this.userID}})
            .then (response =>{
                this.statements = response.data;                
                if(this.statements.length > 0){
                    this.statements.forEach(function(item, index){
                        this.createDate = item.statements.createDate.substring(0,10);
                        this.transactionDescription = item.transactionDescription;
                        this.transactionAmount = item.transactionAmount;                    });
                }               
            })                     
        },
        onRowSelected(items) {
            this.userSelected = items[0];  
        }
  }
}

</script>