<template>
    <div>
        <b-card-code>
            <b-row class="mb-1">
                <b-col>
                    <h2>
                        Busca el usuario a gestionar
                    </h2>
                </b-col>                
            </b-row>            
            <b-row>
                <b-col md="2" xl="2" class="mb-1"> 
                    <b-form-group label="Email" label-for="basicInput">                        
                        <b-form-input v-model = "email" placeholder="Digite el Email"></b-form-input>
                    </b-form-group>                    
                </b-col> 
                <b-col md="2" xl="2" class="mb-1"> 
                    <b-form-group label="Nombres" label-for="basicInput">                        
                        <b-form-input v-model = "firstName" placeholder="Digite el Nombre"></b-form-input>
                    </b-form-group>                    
                </b-col> 
                <b-col md="2" xl="2" class="mb-1"> 
                    <b-form-group label="Apellidos" label-for="basicInput">                        
                        <b-form-input v-model = "lastName" placeholder="Digite el Apellido"></b-form-input>
                    </b-form-group>                    
                </b-col> 
                <b-col md="2" xl="2" class="mb-1"> 
                    <b-form-group label="Documento" label-for="basicInput">                        
                        <b-form-input v-model.number = "documentNumber" placeholder="Digite Tipo de Documento"></b-form-input>
                    </b-form-group>                    
                </b-col> 
                <b-col v-if="!processing" md="3" xl="3" class="mt-2 ">
                    <b-button variant="primary" @click="GetUsersToManage" block >                
                        <span class="align-middle">Buscar</span>
                    </b-button> 
                </b-col>
                <b-col v-if="processing" md="3" lg="3" class="mt-2">
                    <b-button variant="primary" disabled block><b-spinner small label="Cargando..." /> Cargando...</b-button>
                </b-col>
            </b-row>
        </b-card-code>        

        <b-card-code no-body>
            <b-table 
            v-if="usersManagement.length>0"
            ref="selectableTable"
            :fields="fields" 
            :items="usersManagement" 
            responsive
            selectable
            class="mb-0"
            :select-mode="selectMode"
            @row-selected="onRowSelected">

                <template #cell(profileType)="data">                    
                    <b-badge v-if="data.item.profileType=='Inversionista'" pill variant="success">{{(data.item.profileType)}}</b-badge>
                    <b-badge v-if="data.item.profileType=='Productor'" pill variant="warning">{{(data.item.profileType)}}</b-badge>                    
                </template> 

                <template #cell(email)="data">
                    {{(data.item.email)}}
                </template> 

                <template #cell(firstName)="data">
                    {{(data.item.firstName)}}
                </template> 

                <template #cell(lastName)="data">
                    {{(data.item.lastName)}}
                </template> 

                <template #cell(documentNumber)="data">
                    {{(data.item.documentNumber)}}
                </template> 

                <template #cell(balance)="data">
                    {{ data.item.balance ? parseFloat(data.item.balance).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") : 0.00}}
                </template>

            </b-table>

            <b-row v-if="usersManagement.length <= 0" >
                <b-col xl="4" md="6" lg="4" offset-lg="4" offset-md="3" offset-xl="4"  >
                    <b-alert variant="primary" show>
                        <div class="alert-body">
                            <span><strong>No hay usuarios con esa descripción</strong></span>
                        </div>
                    </b-alert>
                </b-col>
            </b-row> 
        </b-card-code>

        <b-modal centered ref="my-modal" hide-footer title="Que deseas hacer?">
            <b-button class="mt-1" variant="info" block @click="showEditBalanceModal">
                <feather-icon icon="EditIcon" class="mr-50"/>
                <span class="align-middle">Editar Saldo</span>
            </b-button>
            <b-button class="mt-1" variant="outline-primary" block @click="changePassword">
                <feather-icon icon="LockIcon" class="mr-50"/>
                <span class="align-middle">Definir Contraseña</span>
            </b-button>
            <b-button class="mt-1" variant="warning" block @click="showStatementsModal">
                <feather-icon icon="ListIcon" class="mr-50"/>
                <span class="align-middle">Ver Extractos</span>
            </b-button>
            <b-button v-if="this.profileType == 'Productor'" class="mt-1" variant="primary" block @click="convertToNeoganadero">
                <feather-icon icon="TrendingUpIcon" class="mr-50"/>
                <span class="align-middle">Convertir a NeoGanadero</span>
            </b-button> 
            <b-button v-if="this.profileType == 'Inversionista'" class="mt-1" variant="primary" block @click="convertToProductor">
                <feather-icon icon="HomeIcon" class="mr-50"/>
                <span class="align-middle">Convertir a Productor</span>
            </b-button> 
            <b-button v-if="this.profileType == 'Productor'" class="mt-1" variant="danger" block @click="convertToMixto">
                <feather-icon icon="EditIcon" class="mr-50"/>
                <span class="align-middle">Convertir a perfil Mixto Productor/NeoGanadero</span>
            </b-button> 
            <b-button v-if="this.profileType == 'Inversionista'" class="mt-1" variant="outline-primary" block @click="viewInvestorDetails">
                <feather-icon icon="EyeIcon" class="mr-50"/>
                <span class="align-middle">Detalles NeoGanadero</span>
            </b-button>
            <b-button v-if="this.profileType == 'Inversionista' && this.holdingTaxEnabled" class="mt-1" variant="warning" block @click="markAsTaxHolder">
                <feather-icon icon="CheckSquareIcon" class="mr-50"/>
                <span class="align-middle">Desmarcar como retenedor en la fuente</span>
            </b-button>
            <b-button v-if="this.profileType == 'Inversionista' && (this.holdingTaxEnabled == null || this.holdingTaxEnabled == false)" class="mt-1" variant="warning" block @click="markAsTaxHolder">
                <feather-icon icon="CheckSquareIcon" class="mr-50"/>
                <span class="align-middle">Marcar como retenedor en la fuente</span>
            </b-button>
            <b-button v-if="this.profileType == 'Inversionista'" class="mt-1" variant="danger" block @click="showSiigoModal">
                <feather-icon icon="CheckSquareIcon" class="mr-50"/>
                <span class="align-middle">Asociar Tercero Siigo</span>
            </b-button>
            <b-button v-if="this.profileType == 'Inversionista'" class="mt-1" variant="primary" block @click="createTerceroInSiigo">
                <feather-icon icon="EditIcon" class="mr-50"/>
                <span class="align-middle">Crear Tercero en Siigo</span>
            </b-button>
            <b-button v-if="this.profileType == 'Inversionista' && this.validDocuments != true" class="mt-1" variant="outline-primary" block @click="markValidDocuments">
                <feather-icon icon="CheckSquareIcon" class="mr-50"/>
                <span class="align-middle">Marcar Documentos Validos</span>
            </b-button>
        </b-modal>

        <b-modal centered ref="setNewPassword" hide-footer title="Digita la nueva contraseña">  
            <b-col>
                <b-form-group label="Contraseña:" label-for="basicInput">                        
                    <b-form-input v-model = "newPassword" placeholder="Digite nueva Contraseña"></b-form-input>
                </b-form-group>                               

                <b-button class="mt-2" variant="primary" block @click="setNewPassword">
                    <feather-icon icon="lockIcon" class="mr-50"/>
                    <span class="align-middle">Guardar Contraseña</span>
                </b-button> 
            </b-col>      
        </b-modal>

        <b-modal size="lg" ref="view-statement-modal" title="Extractos" centered hide-footer>            
            <view-statement-component :userID = this.userID></view-statement-component>
            <b-row  class="justify-content-end">
                <b-col class="mt-1 mb-1" md="4" lg="4">
                    <b-button variant="primary" @click="hideStatementsModal" block>                                                            
                        Cerrar
                    </b-button>
                </b-col>

            </b-row> 
        </b-modal>

        <b-modal ref="editBalanceModal" hide-footer title="Digite el nuevo saldo">
            <b-input-group >
                <b-input-group-prepend is-text >
                <feather-icon size="18" icon="DollarSignIcon" style="color:#00bd56" />
                </b-input-group-prepend>
                <cleave 
                style="margin-left:0;"                        
                id="vi-first-name"
                v-model.number="userBalance"
                value-field="userBalance"
                class="form-control "
                :options="options.number" 
                placeholder="Digita el valor"/>
                </b-input-group>
            <b-button class="mt-1" variant="primary" block @click="updateUserBalance">
                <feather-icon class="mr-50"/>
                <span class="align-middle">Actualizar</span>
            </b-button> 
        </b-modal>

        <b-modal ref="siigoModal" hide-footer title="Asociar tercero Siigo:">                            
            <b-row>
                <b-col md="12" xl="12" class="mb-1">                    
                    <b-form-textarea v-model="siigoCustomerID" id="textarea-default" placeholder="Digite" rows="2"/>
                </b-col>                
            </b-row>        
            <b-button  variant="primary" block @click="associateSiigoCustomerID">
                <feather-icon class="mr-50"/>
                <span class="align-middle">Asociar</span>
            </b-button>
        </b-modal>
    </div>
</template>

<script>
import axios from '@axios'
import BCardCode from '@/@core/components/b-card-code/BCardCode.vue';
import { BAlert, BTable, BForm, BFormInput, BProgress, BInputGroupPrepend, BInputGroup, BFormGroup, BFormSelect, BSpinner, BButton, BRow, BCol,
BBadge,BFormDatepicker,BFormTextarea} from 'bootstrap-vue'
import departamentos from '../../assets/departamentos-municipios.json'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import ViewStatementComponent from '@core/components/ViewStatementComponent'

export default {
    components: {
        BFormDatepicker,
        BCardCode,
        BTable,
        BForm,
        BProgress,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BInputGroupPrepend, 
        BInputGroup,
        BButton,
        BFormSelect,
        BAlert,
        BSpinner,
        BFormTextarea,
        Cleave,
        ViewStatementComponent
    },
    data(){
        return{
            selectMode: 'single',
            fields: [
                {key: 'profileType', label: 'Tipo de Usuario'},
                {key: 'email', label: 'Email'},                
                {key: 'firstName', label: 'Nombres'},
                {key: 'lastName', label: 'Apellidos'},
                {key: 'documentNumber', label: 'Documento'},
                {key: 'balance', label: 'Saldo Sosty'}
            ],
            fieldsViewStatement: [
                {key: 'createDate', label: 'Fecha'},
                {key: 'transactionDescription', label: 'Descripción'},                
                {key: 'transactionAmount', label: 'Monto transacción'}
            ],
            options: {
                date: {
                date: true,
                delimiter: '-',
                datePattern: ['Y', 'm', 'd'],
                },
                number: {
                numeral: true,
                numeralThousandsGroupStyle: 'thousand',
                },
            }, 
            processing: false,
            profileType: null,
            email: null,
            firstName: null,
            lastName: null,
            documentNumber: null,
            usersManagementCard: false,
            usersManagement: [{}],
            userID: '',
            newPassword: null, 
            userBalance: null, 
            newUserBalance: null, 
            holdingTaxEnabled: null,  
            siigoCustomerID: null,   
            newSiigoCustomerID: null,
            validDocuments: null,
            createDate: null,
            transactionDescription: null,
            transactionAmount: null,
            statements: [{}],
        }
    },

    methods:{
        GetUsersToManage(){            
            if(this.email && this.email.length>2 
                    || this.firstName && this.firstName.length>2
                    || this.lastName && this.lastName.length>2
                    || this.documentNumber&& (this.documentNumber).toString().length>2 ){

                this.processing = true;              
                axios.get('/User/GetUsersToManage',  { params: { email: this.email, firstName: this.firstName,  
                lastName: this.lastName , documentNumber: this.documentNumber}})
                .then(response => {
                    this.processing = false; 
                    this.usersManagementCard = true;                                                        
                    this.usersManagement = response.data;
                    if(this.usersManagement > 0){
                        this.usersManagement.forEach(function(item,index){                            
                        })
                    }                                  
                })
            } else {
                this.$bvToast.toast("Digita al menos 3 caracteres en un campo", { title: 'Ok', variant: 'danger', solid: true})   
            }  
        },
        onRowSelected(items) {
            this.userSelected = items[0];               
            this.userID = this.userSelected.userID             
            this.profileType = this.userSelected.profileType    
            this.userBalance = this.userSelected.balance
            this.siigoCustomerID =  this.userSelected.siigoCustomerID
            this.holdingTaxEnabled = this.userSelected.holdingTaxEnabled
            this.validDocuments = this.userSelected.validDocuments
            if(this.userSelected) this.showModal();
        },
        showModal() {
            this.$refs['my-modal'].show()
        },
        hideModal(){
            this.$refs['my-modal'].hide()
        },
        changePassword(){            
            this.$refs['my-modal'].hide()
            this.$refs['setNewPassword'].show()            
        },        
        setNewPassword(){ 
            this.mail = this.userSelected.email           
            axios.get('/User/setNewPassword',  { params: { email: this.mail, newPassword: this.newPassword}})
            .then(response =>{
                if(response){
                    this.$refs['setNewPassword'].hide()
                    this.$bvToast.toast("Contraseña asignada con exito", { title: 'Ok', variant: 'success', solid: true})   
                } else{
                    this.$bvToast.toast("Hubo un problema", { title: 'Ok', variant: 'danger', solid: true})
                }                
            })            
        },        
        convertToNeoganadero(){            
            axios.get('/User/ConvertToNeoganadero',  { params: { userID:this.userID}})
            .then(response =>{
                if(response){
                    this.$refs['my-modal'].hide()
                    this.$bvToast.toast("Usuario convertido a NeoGanadero", { title: 'Cambio exitoso', variant: 'success', solid: true})   
                    this.GetUsersToManage()
                } else{
                    this.$bvToast.toast("Hubo un problema", { title: 'Ok', variant: 'danger', solid: true})
                }               
            })            
        },
        convertToProductor(){
            axios.get('/User/ConvertToProductor',  { params: { userID:this.userID}})
            .then(response =>{
                if(response){
                    this.$refs['my-modal'].hide()
                    this.$bvToast.toast("Usuario convertido a Productor", { title: 'Cambio exitoso', variant: 'success', solid: true})   
                    this.GetUsersToManage() 
                } else{
                    this.$bvToast.toast("Hubo un problema", { title: 'Ok', variant: 'danger', solid: true})
                }               
            })  
        },
        convertToMixto(){
            axios.get('/User/ConvertToMixto',  { params: { userID:this.userID}})
            .then(response =>{
                if(response){
                    this.$refs['my-modal'].hide()
                    this.$bvToast.toast("Usuario convertido a Productor/NeoGanadero", { title: 'Cambio exitoso', variant: 'success', solid: true})   
                    this.GetUsersToManage() 
                } else{
                    this.$bvToast.toast("Hubo un problema", { title: 'Ok', variant: 'danger', solid: true})
                }               
            })              
        },
        viewInvestorDetails(){    
            let route = this.$router.resolve({path: 'edit-investor-profile?investorID=' + this.userSelected.userID });
            window.open(route.href, '_blank');
        },

        showEditBalanceModal(){
            this.$refs['my-modal'].hide()
            if(this.userBalance == null){
                this.userBalance = 0
            }              
            this.$refs['editBalanceModal'].show()                                  
        },

        updateUserBalance(){            
            this.$refs['editBalanceModal'].hide() 
            this.newUserBalance = this.userBalance            

            axios.get('/User/UpdateUserBalance',  { params: { userID:this.userID, newUserBalance:this.newUserBalance}})
            .then(response =>{
                if(response){
                    this.$refs['editBalanceModal'].hide()
                    this.$bvToast.toast("El saldo ha sido actualizado", { title: 'Saldo Actualizado', variant: 'success', solid: true})                       
                } else{
                    this.$bvToast.toast("Hubo un problema", { title: 'Ok', variant: 'danger', solid: true})
                }               
            })            
        },
        markAsTaxHolder(){
            this.$refs['my-modal'].hide()
            axios.get('/User/MarkAsTaxHolder',  { params: { userID:this.userID}})
            .then(response =>{
                if(response){                    
                    this.$bvToast.toast("Información Actualizada", { title: 'Retenedor en la fuente', variant: 'success', solid: true})                       
                } else{
                    this.$bvToast.toast("Hubo un problema", { title: 'Ok', variant: 'danger', solid: true})
                }               
            }) 
        },
        showSiigoModal(){
            this.$refs['my-modal'].hide()
            this.$refs['siigoModal'].show()
        },
        associateSiigoCustomerID(){   
            this.newSiigoCustomerID = this.siigoCustomerID         
            axios.get('/User/AssociateUserWithSiigoCustomerID',  { params: { userID:this.userID, siigoCustomerID: this.newSiigoCustomerID}})
            .then(response =>{
                this.$refs['siigoModal'].hide()
                if(response){                    
                    this.$bvToast.toast("Información Actualizada", { title: 'Ok', variant: 'success', solid: true})                       
                } else{
                    this.$bvToast.toast("Hubo un problema", { title: 'Wrong', variant: 'danger', solid: true})
                }
                this.$refs['siigoModal'].hide()  
                window.location.reload()
            }) 
        },
        createTerceroInSiigo(){
            axios.get('/User/CreateTerceroInSiigo',  { params: { userID:this.userID}})
            .then(response =>{
                this.$refs['my-modal'].hide()
                if(response){                    
                    this.$bvToast.toast("Tercero Siigo creado", { title: 'Ok', variant: 'success', solid: true})                       
                } else{
                    this.$bvToast.toast("Hubo un problema", { title: 'Wrong', variant: 'danger', solid: true})
                }
                this.$refs['my-modal'].hide()  
                window.location.reload()
            })
        },
        markValidDocuments(){            
            axios.get('/User/MarkValidDocuments',  { params: { userID:this.userID}})
            .then(response =>{
                if(response){                    
                    this.$bvToast.toast("Información Actualizada", { title: 'Retenedor en la fuente', variant: 'success', solid: true})
                    window.location.reload()                   
                } else{
                    this.$bvToast.toast("Hubo un problema", { title: 'Ok', variant: 'danger', solid: true})
                }               
            }) 
        },
        showStatementsModal(){
        this.$refs['view-statement-modal'].show()
        },
        hideStatementsModal(){
            this.$refs['view-statement-modal'].hide()
        },
    },
    directives: {
        Ripple,
    },
}
</script>